#countdown-left-text-desktop {
    font-size: 12px;
    color: #ffffff;
    font-weight: bold;
    margin-left: 10px;
    max-height: 30px;
    line-height: 30px;
    /* Add this line */
    overflow: hidden;
    /* Add this line */
    /* display: flex;
    align-items: center; */

    span {
        max-height: 40px;
        line-height: 40px;
        /* Add this line */

        b {
            max-height: 40px;
            line-height: 40px;
            /* Add this line */
        }
    }

    @media only screen and (max-width: 455px) {
        height: 40px !important;
        max-height: 40px !important;
        line-height: 40px;

    }
}

@media only screen and (max-width: 455px) {
    .page-no-subscription-header {
        height: 40px !important;
        max-height: 40px !important;
        line-height: 40px;
    }
}

@media only screen and (max-width: 767px) {
    #countdown-left-text-desktop {
        display: none !important;
    }
}

#no-subscription-header-text {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    font-family: 'Roboto' !important;
    max-height: 40px;
    line-height: 40px;
    /* Add this line */
    overflow: hidden;
    /* Add this line */
    /* display: flex;
    align-items: center; */

    span {
        max-height: 40px;
        line-height: 40px;
        /* Add this line */

        b {
            max-height: 40px;
            line-height: 40px;
            /* Add this line */
        }
    }

    @media only screen and (max-width: 660px) {
        font-size: 10px;
        line-height: 13px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        max-height: 3em;
    }

    @media only screen and (max-width: 374px) {
        font-size: 8px;
    }
}

@media only screen and (max-width: 374px) {
    .time-left-text {
        font-size: 8px !important;
    }
}